const Minimise = ({toggleVisibility}) => {
    return ( 
        <>
        <p className="minimise" 
        onClick={toggleVisibility}>
            ↑ Minimise ↑</p>
        </>
     );
}
 
export default Minimise;