const Anthologies = () => {
    return ( 
<section>
<h3><u>Anthologies</u></h3>
      <ul className="anthologies">
        <li><i>Forty Voices Strong</i>, 2019 (Grayson Books) <a href="https://graysonbooks.com/forty-voices-strong.html">[Buy Here<img id='external-link' title='external link' src='/images/external-link.png' />]</a></li>
        <li><i>Umbrellas of Edinburgh</i>, 2020 (Shoreline of Infinity) <a href="https://www.shorelineofinfinity.com/product/umbrellas-of-edinburgh/">[Buy Here<img id='external-link' title='external link' src='/images/external-link.png' />]</a></li>
        <li><i>The Last Song: Word for Frightened Rabbit</i>, 2023 (Broken Sleep Books) <a href="https://www.brokensleepbooks.com/product-page/ed-aaron-kent-maria-sledmere-the-last-song-words-for-frightened-rabbit">[Buy Here<img id='external-link' title='external link' src='/images/external-link.png' />]</a></li>
        <li><i>Masculinity: an anthology of modern voices</i>, 2024 (Broken Sleep Books) <a href="https://www.brokensleepbooks.com/product-page/masculinity-an-anthology-of-modern-voices">[Buy Here<img id='external-link' title='external link' src='/images/external-link.png' />]</a></li>
        {/* <li><i>The Spec Book 2024</i>, 2024 (Speculative Books) <a href="https://www.brokensleepbooks.com/product-page/masculinity-an-anthology-of-modern-voices">[Buy Here<img id='external-link' title='external link' src='/images/external-link.png' />]</a></li> */}
      </ul>
      <br />
</section>

     );
}
 
export default Anthologies;